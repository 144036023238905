export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/(app)/admin/clients": [5,[2]],
		"/(app)/admin/inspections": [6,[2]],
		"/(app)/admin/inspections/edit-new/[inspectionId]": [7,[2]],
		"/(app)/admin/instruments": [8,[2]],
		"/(app)/admin/instruments/edit-new/[instrumentId]": [9,[2]],
		"/(app)/admin/measurement-qualifiers": [10,[2]],
		"/(app)/admin/measurement-qualifiers/edit-new/[measurementId]": [11,[2]],
		"/(app)/admin/procedures": [12,[2]],
		"/(app)/admin/procedures/edit-new/[proceduresId]": [13,[2]],
		"/(app)/admin/regulations": [14,[2]],
		"/(app)/admin/regulations/edit-new/[regulationID]": [15,[2]],
		"/(app)/admin/system": [16,[2]],
		"/(app)/admin/tests-protocols": [17,[2]],
		"/(app)/admin/tests-protocols/edit-new/[testsProtocolId]": [18,[2]],
		"/(app)/admin/users": [19,[2],[,3]],
		"/(auth)/create-account/[userId]": [21],
		"/(app)/home": [20,[2]],
		"/(auth)/login": [22]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';